.navbar {
	background-color: #FFF;
}

// .btn-cta {
// 	background-color: #f8f8f8;
// 	color: $primary;
// 	&:hover {
// 		color: #f8f8f8;
// 		background-color: $primary;
// 	}
// }

.btn-cta {
	font-size: 14px;
}

.navbar.background-color-primary {
	.nav-link {
		color: $primary;
		&:hover, &:focus, &:active {
			color: $secondary;
		}
		&:visited {
			color: $primary;
		}
	}
}

.navbar .btn-link {
	color: $primary-contrast;
	background-color: $primary;

	&:hover, &:active, &:visited, &:focus {
		color: $primary-contrast;
		background-color: $primary;
	}
}

.btn-success {
	background-color: $primary;
	&:disabled {
		background-color: rgb(63, 63, 63);
	}
	&:hover {
		background-color: #f8f8f8;
	}
}

.cover-hero {
	.h-100 {
		.row {
			.col-sm-8 {
				margin: auto;
			}
		}
	}
}

.eddmpricing-pricinggrid-section {
	.background-color-primary {
		.color-secondary {
			color:#FFF;
		}

		ul.list-unstyled.arrow-list-item li:before {
			color:#FFF;
		}
	}
}
